
import * as Components from "./Shiso"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "shiso"
}

