import React, {useState} from 'react'

export default function AutoMenuNavigator ({subMenus}) {
  const [expanded, setExpanded] = useState(false)
  function clickLink (catId) {
    const yOffset= -180
    const titleEle = document.getElementById(`menu-category-${catId}`)
    const y = titleEle.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
    
  }
  return(
    <div className="menu-nav-wrapper">
      <nav
        className="menu-navigation"
        role="navigation"
        aria-label="Menu Categories">
        {subMenus.filter(({tag}) => {
              if (tag && (tag.indexOf('hide') !== -1)) {
               return false
              }
              return true
            })
          .map(({id, name, description, disableOrder}) => {
          return(
            <a
              onClick={(e) => {
                e.preventDefault()
                  clickLink(id)
              }}
              className="category-link-item"
              href="">{name && name.en}</a>
          )
        })}
      </nav>
    </div>
  )
}
