import React, { Component, PureComponent, useContext } from 'react';
import {NavLink, Link, Redirect} from 'react-router-dom'
// import Carousel from 'nuka-carousel';
import About from './About'
import Order from './Order'
// import Menu from './Menu'
import Specials from './Pages/Specials'
import Catering from './Pages/Catering'
import LogoBar from './svg/LogoBar'
import LogoLarge from './svg/LogoLarge'
// import Bakery from './Bakery'
// import GiftCards from './GiftCards'
// import Spirits from './Spirits'
// import Delivery from './Delivery'
import './index.scss'
import './ThemeToggle/theme_night.scss'
// import './wine.scss'
import ThemeContext from '../../Context/ThemeContext'

import {sections} from './sections'
import ThemeToggle from './ThemeToggle'
import CartBar from './CartBar'
import AutoMenu from './AutoMenu'
export {CartBar as StickyTopBar}



export const customOrderAppPaths = ["/menu", "/order"]

export const pages = [
  // {path: "/", label: "Home",  },
  {path: "menu", label: "Menu" },
  // {path: "https://nohoramenkitchenla.com/catering/", external: true, label: "Catering", component: Catering },

  {path: "/order", label: "Order" },
  // {path: "/about", label: "About", component: About },
  // {path: "https://nohoramenkitchenla.com/contact/", label: "Contact", external: true },

]

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label, external, hidden, unlisted}) => {
          if (hidden || unlisted) { return null}
          return (
          <li>
            {external ?
              <a className="page-link" href={path}>{label}</a> :
              <NavLink className="page-link" exact to={path}>{label}</NavLink>}
          </li>
        )})}
      </ul>
      <ThemeToggle></ThemeToggle>
    </nav>
  )}
}

const quickLinks = [
  // { to: '/order', label: 'order online'},
  { to: '/bakery', label: 'bakery'},
  { to: '/breakfast-lunch', label: 'marketplace'},
  { to: '/catering', label: 'catering'},
]

const foodImages = [
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-1.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-2.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-3.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-4.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-5.jpg"}
]



export function Header () {

    const {themeName} = useContext(ThemeContext)
    const heroUrl = (themeName === 'dark') ?
      "https://afag.imgix.net/romancing-the-bean-cafe/espress-dark.jpg?w=500"
      :
     "https://afag.imgix.net/romancing-the-bean-cafe/espresso-light.jpg?w=500"
    return (
      <div className="landing-page-wrapper">
        <div className="hero-wrapper">
          <div className="hero-banner">
            {/* <div className="text">
              <h2>Takeout / Delivery ONLY</h2>
              <p>All dishes made to order</p>

              <p><a href="tel:+12622429977">(262) 242-9977</a></p>

              <ul>
                <li>
                  Order <Link to="/order">Pickup / Curbside</Link><br/><small>(write car model next to name)</small>
                </li>
                <li>
                  Order <Link to="/order">drop-at-door Delivery</Link>
                </li>

              </ul>
            </div> */}
            <div>
              <Link to="/order" className="order-online-button">Order Pickup / Delivery</Link>
            </div>
          </div>
          <div className="logo-banner">
            <LogoLarge
              themeName={themeName}
              className="hero-logo"
              ></LogoLarge>


          </div>
        </div>


        <div className="location-info">
          <div className="text">
            {/* <div className="service">
              <h2>Pickup / Delivery</h2>
              <p></p>
            </div> */}
            <div className="location">
              <h2>
                Location
              </h2>
              <p>11713 Saticoy St, <br/>North Hollywood, CA 91605</p>
            </div>
            <div className="telephone">
              <h2>
                Telephone
              </h2>
              <p><a href="tel:+18185794677">(818) 579-4677</a></p>
            </div>
            <div className="hours-section">
              <h2>Hours</h2>
              {/* <p>Takeout</p> */}
              <div className="hours-details">
                <p className="days">Mon-Thu</p>
                <p className="hours">11:30am - 3pm</p>
                <p className="hours">5pm - 10pm</p>
                <p className="days">Fri</p>
                <p className="hours">11:30am - 3pm</p>
                <p className="hours">5:00pm - 10:30pm</p>
                <p className="days">Sat</p>
                <p className="hours">1pm - 10:30pm</p>
                <p className="days">Sun</p>
                <p className="hours">1pm - 9:30pm</p>

              </div>
            </div>
          </div>


        </div>
        <AutoMenu
          subMenuFilter={subMenuFilter}  
          />
        {/* <h2
          className="menu-header"
          style={{
            marginTop: '1em',
            textAlign: 'center',

          }}
          >Our Menu</h2> */}

        {/* <div
          style={{
            textAlign: 'center',
            marginTop: '1em'
          }}
          className="tp">
          <p>Orders over $30 get 2 free TP rolls (not the edible kind)</p>
        </div> */}

        {/* <div
          className="hero-banner">
          <div className="text">
            <h2 style={{

            }}>
            <span className="welcome">welcome to</span>
            <br/>
            <img
              style={{
                maxWidth: "350px"
              }}
              src="https://afag.imgix.net/electric-lotus/logo.png?gam=100&invert=true" alt=""/>

            </h2>

            <p>
              <Link to="/order" className="order-online-button">Order Pickup / Delivery</Link>
            </p>
          </div>
        </div>

        <div className="text-section">
          <div className="header">
            <div className="deco">
              <h1>
                <div className="title">Experience the Taste of India!</div>

              </h1>

            </div>
          </div>
          <div className="content">

            <p>We offer a richly-varied menu of fresh, natural ingredients, wholesome sauces and flavorful spices. All of our food is natural and freshly-prepared daily on the premises with no additives or preservatives.</p>
          </div>
        </div> */}

      {/* <AutoMenu
        width={600}
        ></AutoMenu> */}

      </div>







    );

}


export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="ordering-header">
        {/* <PoweredBy></PoweredBy> */}
      </div>
    );
  }
}

export function subMenuFilter (subMenu) {
  
  const tag = subMenu.tag || ''
  // if (!tag) {return true}

  const now = new Date()
  const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  const minutes = now.getMinutes() // 0 to 59
  const isWeekday = [1,2,3,4,5].indexOf(day) !== -1
  const isLunch = (
    (hours >= 0) && (hours < 15)
  )
  const isWeekdayBreakfast = tag.indexOf('weekday-lunch') !== -1 // m-f 6am - 11am
  if (isWeekdayBreakfast) {
    if (isLunch && isWeekday) {
      return true
    } else {
      return false
    }
  }
  // const isBrunch = (
  //   (hours >= 0) && (hours < 15)
  // )
  // const isLunch = (
  //   (hours >= 11) && (hours < 15)
  // )
  // const showLunchMenu = isWeekday && isLunch

  
  // const isEndBreakfast = tag.indexOf('weekend breakfast specials') !== -1 // sat-sun 6am - 3pm
  // const isDayLunch =     tag.indexOf('weekday lunch specials') !== -1     // m-f 11am-3pm

  // if (isDayBreakfast && isWeekday && isBreakfast) { return true }
  // if (isEndBreakfast && !isWeekday && isBrunch) { return true }
  // if (isDayLunch && isWeekday && isLunch) {return true}
  // // if (isDinnerSubmenu && !showLunchMenu) { return true }

  return true
}
